// About component
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Doctor2 from "../../assets/Images/Group7262.png";
import "../../components/aboutus/AboutUs.css";
import { aboutUsData } from "../../Reducers/aboutSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { Link } from "react-router-dom";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";

function About() {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { AboutUsData, status, error } = useSelector((state: RootState) => state.about);
    const [showMore, setShowMore] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        dispatch(aboutUsData());

        dispatch(setMobileHeadingData("About"))
        dispatch(resetAllCardData());


    }, [dispatch]);
    console.log(AboutUsData, 'this is my about us data');

    const handleReadMore = (index: number) => {
        setShowMore((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <div className='container backgroundblur'>
            <div
          className="mainHeadAbout"
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "700",
            paddingTop: "10px",
          }}
        >
          <span>About us</span>
        </div>
        <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>


                    {/* {!showMore[0] ? (
                        <span onClick={() => handleReadMore(0)} className="text-primary read-more">
                            Read More...
                        </span>
                    ) : (
                        <span style={{ textAlign: "justify", marginTop: "10px" }}>
                            It has roots in a piece of classical Latin literature from 45 BC,
                            making it over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia, looked up one of
                            the more obscure Latin words, consectetur, from a Lorem Ipsum
                            passage, and going through the cites of the word in classical
                            literature, discovered the undoubtable source. Lorem Ipsum comes
                            from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                            Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
                            BC.
                            <p onClick={() => handleReadMore(0)} className="text-primary read-more">
                                Read Less
                            </p>
                        </span>
                    )} */}
                </div>
            </div>
            <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>

                </div>
            </div>

            <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>

                </div>
            </div>

            <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>

                </div>
            </div>

            <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>

                </div>
            </div>

            <div className="container card  mb-3 allCard">
                <div className="row  aboutrow">
                    <div className="col-md-3 col-3" style={{padding:"0px"}}>
                        <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor"/>
                    </div>
                    <div className="col-md-9 col-9 text Web-about" >
                        <span className='headText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.  <div style={{ textAlign: "justify" }} className='textContent'>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de.
                                BC.K.JRE
                            </div>
                        </span>
                    </div>

                    <div className="col-md-9 col-9 text mob-about" >
                        <span className='headMobText'>
                            <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.  <div style={{ textAlign: "justify" }} className='textMobContent'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text &nbsp;
                            <span><Link to="" style={{color:"rgba(36, 116, 150, 1)"}} className="a-abot">Read More...</Link></span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
